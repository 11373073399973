import { Controller } from "@hotwired/stimulus";

class HomepageHighligtParams extends Controller {
  totalCountValue!: Number;
  itemsTargets!: HTMLElement[];
  indexDisplayTarget!: HTMLElement;
}

export default class extends (Controller as typeof HomepageHighligtParams) {
  index = 0;
  interval = 0;

  static values = {
    totalCount: Number,
  }

  connect(): void {
    if (this.totalCountValue <= 1) return;

    setInterval(() => {
      this.interval += 1;
      if (this.interval == 5) this.showNext();
    }, 1000)
  }

  static targets = ["items", "indexDisplay"];

  next(event: MouseEvent): void {
    this.showNext()
  }

  prev(event: MouseEvent): void {
    this.showPrevious()
  }

  showPrevious(): void {
    this.itemsTargets[this.index].style.opacity = "0";
    this.itemsTargets[this.index].style.zIndex = "1";

    this.itemsTargets[this.previousIndex].style.zIndex = "10";
    this.itemsTargets[this.previousIndex].style.opacity = "1";
    this.index = this.previousIndex
    this.resetInterval()
    this.setDisplayedIndex()
  }

  get previousIndex() {
    if(this.index <= 0) return (this.totalCountValue as number) - 1
    return this.index - 1
  }

  setDisplayedIndex() {
    this.indexDisplayTarget.innerHTML = `${this.index + 1}/${this.totalCountValue}`
  }

  resetInterval() {
    this.interval = 0;
  }

  showNext() {
    this.itemsTargets[this.index].style.opacity = "0";
    this.itemsTargets[this.index].style.zIndex = "1";

    this.itemsTargets[this.nextIndex].style.opacity = "1";
    this.itemsTargets[this.nextIndex].style.zIndex = "10";
    this.index = this.nextIndex
    this.resetInterval()
    this.setDisplayedIndex()
  }

  get nextIndex() {
    if ((this.index + 1) >= this.totalCountValue) return 0;
    return this.index + 1;
  }
}
