import { Controller } from "@hotwired/stimulus";
import { Pagination, Project } from "../lib/props";
import * as qs from "query-string"
import axios from "axios";
import { make } from "../lib/dom";
import defaultImage from "../../images/icons/rectangle-3x2-monochrome.svg";
import slashDefault from "../../images/icons/slash-default.svg"

class WorksProps extends Controller {
  loadingTarget: HTMLElement;
  footer: HTMLElement;
  paginationValue!: Pagination
  loading: boolean
}

export default class extends (Controller as typeof WorksProps) {
  static values = {
    pagination: Object
  }
  static targets = ["loading"]

  connect(): void {
    this.footer = document.querySelector("footer")
    this.loading = false;
    this.hide()
    if (!this.paginationValue.next) return;
  }

  paginate(): void {
    if (this.loading || !this.neetToLoad || !this.paginationValue.next) return;
    this.loadPage(this.paginationValue.next)
  }

  loadPage(page: number): void {
    this.loading = true;
    const url = window.location.pathname
    const params = qs.parse(window.location.search)
    params["page"] = `${page}`
    this.show()
    axios.get(`${url}.json`, { params: params}).then(({ data: { entries, pagination } }) => {
      this.paginationValue = pagination
      this.createElement(entries)
    }).finally(() => {
      this.hide()
    })
  }

  get neetToLoad(): boolean {
    const position = this.footer.getBoundingClientRect();
    return position.top < window.innerHeight && position.bottom >= 0
  }

  /*
  *  <div class="item-grid break-words all-works">
  *    <a href="/works/mobotiq" class="relative block image-container">
  *      <div class="overflow-hidden max-w-full h-1/2 rounded relative ">
  *          <img class="w-full h-auto" src="http://localhost:3000/packs/media/images/icons/rectangle-3x2-monochrome-ae59d7128df039f8a6607226e65bac4a.svg" />
  *      </div>
  *      <div class="absolute w-10 h-10 z-20 b-0" style="bottom: -15px;">
  *        <img src="/packs/media/images/icons/slash-default-5766a0fef54623bdbca86dd282afc238.svg" />
  *      </div>
  *    </a>
  *
  *    <div class="mt-6 mb-4">
  *        <a href="/works?category=1" class="font-bold mr-3 inline-block">:ddsadsa</a>
  *        <a href="/works?category=2" class="font-bold mr-3 inline-block">:kuda</a>
  *    </div>
  *
  *    <a href="/works/mobotiq">
  *      <h3 class="font-display text-black font-bold text-2xl lg:text-4xl">Mobotiq</h3>
  *    </a>
  *  </div>
   */

  createElement(entries: Array<Project>): void {
    for(const i in entries) {
      const project = entries[i]
      const container = make("div", ["item-grid", "break-words", "all-works"]);
      const categoryContainer = make("div", ["mt-6", "mb-4"])

      for(const ci in project.categories) {
        const category = project.categories[ci]
        const el = make("a",["font-bold", "mr-3", "inline-block"], {}, {href: `/works?category=${category.id}`})
        el.innerHTML = `:${category.name}`
        categoryContainer.appendChild(el)
      }

      const titleContainer = make("a", [], {}, {href: `/works/${project.slug}`})
      const title = make("h3", ["font-display", "text-black", "font-bold", "text-2xl", "lg:text-4xl"])
      title.innerHTML = project.client
      titleContainer.appendChild(title)


      const imageLinkContainer = make("a", ["relative", "block", "image-container"], {}, {href: `/works/${project.slug}`})
      const imageContainer = make("div", "overflow-hidden max-w-full rounded relative".split(" "))
      const image = make("img", ["w-full", "h-auto"], {}, {src: project.featured_image || defaultImage})

      const slashContainer = make("div", "absolute w-10 h-10 z-20 b-0".split(" "), {})
      const theSlash = make("img", "h-full w-full".split(" "), {}, {src: slashDefault})
      slashContainer.style.bottom = "-15px";
      slashContainer.appendChild(theSlash)


      imageContainer.appendChild(image)
      imageLinkContainer.appendChild(imageContainer)

      imageLinkContainer.appendChild(slashContainer)

      container.appendChild(imageLinkContainer)
      container.appendChild(categoryContainer)
      container.appendChild(titleContainer)

      this.element.insertBefore(container, this.loadingTarget)
    }

    this.loading = false;
  }

  hide(): void {
    this.loadingTarget.classList.add("hidden")
  }

  show(): void {
    this.loadingTarget.classList.remove("hidden")
  }
}
