import { Controller } from "@hotwired/stimulus"

import Swiper from 'swiper';
import { Mousewheel, Autoplay } from 'swiper/modules';
// import Swiper styles
import 'swiper/css';


class HomepageArchivesProps extends Controller {

}

export default class HomepageArchives extends (Controller as typeof HomepageArchivesProps) {
  interval: NodeJS.Timer
  timeout: NodeJS.Timeout
  readyToScroll: boolean
  swiper: Swiper

  connect(): void {
    this.swiper = new Swiper(this.element as HTMLElement, {
      modules: [Mousewheel, Autoplay],
      direction: 'horizontal',
      slidesPerView: "auto",
      mousewheel: true,
      autoplay: {
        delay: 8000,
        disableOnInteraction: false,
      },
    })

    console.log(this.swiper)
  }

  disconnect(): void {
    if (this.interval) {
      clearInterval(this.interval)
    }

    if (this.timeout) {
      clearTimeout(this.timeout)
    }

    if (this.swiper) {
      this.swiper.destroy(true, true)
    }
  }
}