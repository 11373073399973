import { Controller } from "@hotwired/stimulus";

class BlogDrawerProps extends Controller {
  timeTargets!: Array<HTMLSelectElement>
  draftTarget: HTMLInputElement
}

export default class extends (Controller as typeof BlogDrawerProps) {
  static targets = ["time", "draft"]

  disableTimeSelection(): void {
    for(const i in this.timeTargets) {
      this.timeTargets[i].disabled = true
    }
  }

  enableTimeSelection(): void {
    for(const i in this.timeTargets) {
      this.timeTargets[i].disabled = false
    }

    this.undraft()
  }

  permanentDraft(): void {
    this.draft()
  }

  draft(): void {
    if (!this.draftTarget) return;

    this.draftTarget.value = '1'
  }

  undraft(): void {
    if (!this.draftTarget) return;

    this.draftTarget.value = null
  }
}
