import axios from "axios";
import iziToast from "izitoast";
import { Controller } from "@hotwired/stimulus";
import { hiddenElementChanged } from "../lib/dom";

class AutosaveProps extends Controller {
  formTarget: HTMLFormElement;
  contentTarget: HTMLInputElement;
  watchTargets: Array<HTMLInputElement>;
}

export default class extends (Controller as typeof AutosaveProps) {
  static targets = ["form", "content", "watch"]

  autosaveDuration =  10_000// In Miliseconds

  isTriggered = false;

  changeCount = 0;

  connect(): void {
    console.log(this.watchTargets)

    document.addEventListener("keydown", (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key == "s") {
        e.preventDefault()
        this.isTriggered = true
        this.autoSave()
      }
    })

    this.watchTargets.forEach(target => {
      target.addEventListener("change", () => {
        this.triggerAutosave()
      })
    });

    this.contentTarget.addEventListener(hiddenElementChanged, () => {
      if (this.changeCount < 2) return;
      this.changeCount++;

      this.triggerAutosave()
    })
  }

  triggerAutosave(): void {

    if (this.isTriggered) return
    this.isTriggered = true
    setTimeout(() => {
      this.autoSave()

    }, this.autosaveDuration);
  }

  autoSave(): void {
    iziToast.info({title: "Info", message: "Saving..."})

    const url = this.formTarget.getAttribute("action")
    const form = new FormData(this.formTarget)

    axios.post(url, form).then(() => {
      iziToast.success({title: "Success", message: "Saved.."})
    }).catch(() => {
      iziToast.error({title: "Error", message: "Failed to save"})
    }).finally(() => {
      this.isTriggered = false
    })

  }
}
