import { make } from "../../lib/dom";

export interface UIProps {
  onFileSelected(file: File): void
}

export enum UiStatus {
  Empty = "empty",
  Uploading = "uploading",
  Uploaded = "uploaded",
}

export class UI {
  container: HTMLElement;
  text: HTMLElement;
  person: HTMLElement;
  fileButton: HTMLInputElement;
  preview: HTMLImageElement;
  fileStatus: UiStatus
  icon: HTMLElement;
  imageContainer: HTMLElement;

  constructor({ onFileSelected }: UIProps) {
    this.fileStatus = UiStatus.Empty

    this.container = make("div", ["testimonial", "border", "border-dotted", "my-3", "p-3"]);
    this.person = make("div", ["ce-paragraph", "cdx-block"], {}, {contenteditable: true, "data-placeholder": "Person Name"})
    this.text = make("div", ["ce-paragraph", "cdx-block"], {}, {contenteditable: true, "data-placeholder": "Testimonial Quotes"})

    const label = make("label", ["cursor-pointer"])

    this.imageContainer = make("div", ["overflow-hidden", "w-20", "h-20", "rounded-full", "hidden"])
    this.preview = make("img", ["w-full"], {}, {src: ""}) as HTMLImageElement

    this.imageContainer.appendChild(this.preview)

    this.icon = make("span", ["icon-image", "text-3xl", "text-icon"])

    this.fileButton = make("input", ["hidden"], {}, {type: "file", accept: "image/png, image/jpeg"}) as HTMLInputElement

    this.fileButton.addEventListener("change", (e) => {
      const file = (e.target as HTMLInputElement).files[0]
      if (file) {
        onFileSelected(file)
      }
    })

    label.appendChild(this.icon)
    label.appendChild(this.imageContainer)
    label.appendChild(this.fileButton)

    this.container.appendChild(label)
    this.container.appendChild(this.person)
    this.container.appendChild(this.text)
  }

  render(): HTMLElement {
    return this.container;
  }

  showPreloader(src: string): void {
    this.preview.src = src;
    this.imageContainer.classList.remove("hidden")
    this.icon.classList.add("hidden")
  }

  loadData(data: any) {
    if (data.file) {
      this.preview.src = data.file;
      this.imageContainer.classList.remove("hidden")
      this.icon.classList.add("hidden")
    }

    this.person.innerHTML = data?.person || "";
    this.text.innerHTML = data?.text || "";
  }
}
