import { Controller } from "@hotwired/stimulus"
import iziToast from "izitoast"

class ToasterProps extends Controller {
  errorValue: string
  successValue: string
}

export default class extends (Controller as typeof ToasterProps) {
  static values = {error: String, success: String}

  connect(): void {
    this.printSuccess()
    this.printError()
  }

  printSuccess() {
    if (!this.successValue) return;

    iziToast.success({ title: 'Success', message: this.successValue, position: 'topRight'})
  }

  printError() {
    if (!this.errorValue) return;

    iziToast.error({ title: 'Error', message: this.errorValue, position: 'topRight'})
  }

}
