import { ToolBox } from "../props";
import { UI } from "./ui";
import "./style.scss";
import Uploader from "../double_images/uploader"


export class Testimonial {
  ui: UI;
  config: { endpoints: any; additionalRequestData: any; additionalRequestHeaders: any; field: any; types: any; captionPlaceholder: any; buttonContent: any; uploader: any; actions: any; };
  api: any;
  uploader: any;
  static get toolbox(): ToolBox {
    return {
      icon: `<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150.242V79c0-18.778-15.222-34-34-34H79c-18.778 0-34 15.222-34 34v42.264l67.179-44.192 80.398 71.614 56.686-29.14L291 150.242zm-.345 51.622l-42.3-30.246-56.3 29.884-80.773-66.925L45 174.187V197c0 18.778 15.222 34 34 34h178c17.126 0 31.295-12.663 33.655-29.136zM79 0h178c43.63 0 79 35.37 79 79v118c0 43.63-35.37 79-79 79H79c-43.63 0-79-35.37-79-79V79C0 35.37 35.37 0 79 0z"/></svg>`,
      title: "Testimonial"
    }
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.config = {
      endpoints: config.endpoints || '',
      additionalRequestData: config.additionalRequestData || {},
      additionalRequestHeaders: config.additionalRequestHeaders || {},
      field: config.field || 'image',
      types: config.types || 'image/*',
      captionPlaceholder: this.api.i18n.t(config.captionPlaceholder || 'Caption'),
      buttonContent: config.buttonContent || '',
      uploader: config.uploader || undefined,
      actions: config.actions || [],
    };

    this.uploader = new Uploader({
      config: this.config,
      onUpload: (response) => this.onUpload(response),
      onError: (error) => this.uploadingFailed(error),
    });
    this.ui = new UI({ onFileSelected: (file) => this.onFileSelected(file) })

    this.data = data;
  }
  uploadingFailed(error: any) {
    console.error(error)
  }
  onUpload(response: any) {
    this.ui.showPreloader(response.file.url);
  }

  render() {
    return this.ui.render()
  }

  onFileSelected(file: File): void {
    this.uploader.uploadByFile(file, {
      onPreview: (src) => {
        this.ui.showPreloader(src);
      },
    });
  }

  set data(data: any) {
    this.ui.loadData(data);
  }

  get data() {
     return {
      text: this.ui.text.innerHTML,
      person: this.ui.person.innerHTML,
      file: this.ui.preview.src
    }
  }

  save() {
    return this.data
  }
}
