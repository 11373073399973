import EditorJS from "@editorjs/editorjs";
import ImageTool from '@editorjs/image';
import { ResponsiveTwo } from "../blocks/responsive_two"
import  DoubleImages from "../blocks/double_images"
import Paragraph from "editorjs-paragraph-with-alignment"
import Delimiter from "@editorjs/delimiter"
import { Testimonial } from "../blocks/testimonials"
import Header from "@editorjs/header"
import List from "@editorjs/list";
import SmallImage from "../blocks/small_image"

import { Controller } from "@hotwired/stimulus"
import { hiddenElementChanged } from "../lib/dom";


class TextEditorProps extends Controller {
  inputTarget!: HTMLElement
  inputTargets!: Array<HTMLElement>
  hasInputTarget!: boolean

  hiddenTarget!: HTMLInputElement
  hiddenTargets!: Array<HTMLInputElement>
  hasHiddenTarget!: boolean

  containerTarget!: HTMLElement
  containerTargets!: Array<HTMLElement>
  hasContainerTarget!: boolean
}

export default class extends (Controller as typeof TextEditorProps) {
  static targets = ["input", "container", "hidden"]

  changeEvent = new Event(hiddenElementChanged)

  get content(): any {
    try {
      return JSON.parse(this.hiddenTarget.value)
    } catch (e) {
      return null
    }
  }

  connect(): void {
    const editor = new EditorJS({
      holder: this.inputTarget,
      data: this.content,
      tools: {
        list: {
          class: List,
          inlineToolbar: true,
          config: {
            defaultStyle: 'unordered'
          }
        },
        header: Header,
        delimiter: Delimiter,
        paragraph: {
          class: Paragraph,
          inlineToolbar: true
        },
        responsive_columns: {
          class: ResponsiveTwo,
          inlineToolbar: true,
        },
        image: {
          class: ImageTool,
          config: {
            endpoints: {
              byFile: "/dashboard/uploads",
              byUrl: "/dashboard/uploads/by_url",
            },
            field: "file",
          },
        },
        double_images: {
          class: DoubleImages,
          config: {
            endpoints: {
              byFile: "/dashboard/uploads",
              byUrl: "/dashboard/uploads/by_url",
            },
            types: 'image/jpeg, image/png',
            field: "file",
          },
        },
        small_image: {
          class: SmallImage,
          config: {
            endpoints: {
              byFile: "/dashboard/uploads",
              byUrl: "/dashboard/uploads/by_url",
            },
            field: "file",
          },
        },
        testimonial: {
          class: Testimonial,
          inlineToolbar: true,
          config: {
            endpoints: {
              byFile: "/dashboard/uploads",
              byUrl: "/dashboard/uploads/by_url",
            },
            types: 'image/jpeg, image/png',
            field: "file",
          },
        }
      },
      onChange: () => {
        editor.save().then(output => {
          this.hiddenTarget.value = JSON.stringify(output)
          this.hiddenTarget.dispatchEvent(this.changeEvent)
        }).catch(() => {
          console.log('Error saving details')
        })
      },
    })
  }


}
