import { Controller } from "@hotwired/stimulus"
import { make, removeClass, addClass } from "../lib/dom"

interface Option {
  value: string;
  text: string;
  selected: boolean;
}

class MultipleselectProps extends Controller {
  selectTarget!: HTMLSelectElement;
  selectTargets!: Array<HTMLSelectElement>;
  hasSelectTargets!: boolean;

  placeholderValue!: string;
}

export default class extends (Controller as typeof MultipleselectProps) {
  static targets = ["select"]
  static values = {
    placeholder: String
  }

  _options: Option[];
  _optionsDom: Array<HTMLElement>;
  _selectionDom: HTMLElement;
  _optionContainerDom: HTMLElement;
  _optionOpen: boolean;
  _parentContainerDom: HTMLElement;
  _selectedOptionsDom: Array<HTMLElement>;
  _selectedOptionContainerDom: HTMLElement;


  set options(options: Array<HTMLOptionElement>) {
    this._options = options.map(option => ({ value: option.value, text: option.text, selected: option.selected}))
  }

  get selectedClassList(): Array<string> {
    return ["bg-potassium"]
  }

  get unselectedClassList(): Array<string> {
    return  ["hover:bg-potassium"]
  }

  set optionsDom(options: Array<Option>) {
    const doms = options.map(option => {
      const selectedClass = option.selected ? this.selectedClassList :  this.unselectedClassList
      const el = make("div", ["col-span-6", "px-5", "py-3", "font-bold", "cursor-pointer", ...selectedClass],
        { selected: option.selected, value: option.value, text: option.text },
        { "data-action": "click->multiple-select#changeSelection" }
      )
      el.innerHTML = `: ${option.text}`
      return el;
    })

    this._optionsDom = doms
  }

  createSelectionDom(): HTMLElement {
    const el = make("div", ["w-full", "border", "rounded-md", "cursor-pointer", "flex", "items-center", "px-3", "py-2"],
        {},
        {"data-action": "click->multiple-select#changeOptionVisibility", style: "min-height: 2.5rem;"}
    )
    return el
  }

  createOptionContainerDOM(): HTMLElement {
    const outter = make("div", ["relative"]);
    const container = make("div", [
      "grid",
      "grid-cols-6",
      "gap",
      "option-container",
      "absolute",
      "rounded-lg",
      "shadow-xl",
      "bg-white",
      "z-10",
      "w-full",
      "border",
      "top-0",
      "hidden"
    ])

    for(const i in this._optionsDom) {
      container.appendChild(this._optionsDom[i])

      if (parseInt(i) != this._optionsDom.length - 1) {

        const el = make("hr", ["w-full", "col-span-6"])
        container.appendChild(el)
      }
    }

    outter.appendChild(container)

    return outter
  }

  changeSelectionValues(): void {
    const values = this._selectedOptionsDom.filter(i => {
      const el = i as HTMLOptionElement;
      return el.selected
    })
    .map(i => {
      const el = i as HTMLOptionElement;
      return el.value
    })

    for(let i = 0; i < this.selectTarget.options.length; i++) {
      const option = this.selectTarget.options[i]
      option.selected = values.includes(option.value)
    }
  }

  createParentContainerDOM(): HTMLElement {
    const el = make("div", ["relative"])

    return el
  }

  createSelectedElements(options: Option[]): HTMLElement[] {
    const elements = options.map(option => {
      const el = make("div", ["mr-2", "inline-block"], option) as HTMLOptionElement;

      const text = make("span", ["font-bold"])
      text.innerHTML = `: ${option.text}`

      const button = make("button", ["font-bold", "border", "rounded", "px-1", "ml-2"], {type: "button"})
      button.innerHTML = "&times;"

      const selectedOption = this._optionsDom.find(i => {
        const el = i as HTMLOptionElement;
        return el.value == option.value
      }) as HTMLOptionElement;

      button.onclick = (e: Event) => {
        el.selected = true;
        el.remove()
        selectedOption.selected = false
        this.changeOptionStatus(selectedOption)
        this.changeSelectionValues()
        e.stopPropagation()
      }

      el.appendChild(text)
      el.appendChild(button)

      return el;
    })
    return elements
  }

  changeOptionStatus(option: HTMLOptionElement): void {
    const selectedOption = this._selectedOptionsDom.find(i => {
      const el = i as HTMLOptionElement
      return el.value == option.value
    }) as HTMLOptionElement;

    if (option.selected) {
      removeClass(option, this.unselectedClassList)
      addClass(option, this.selectedClassList)
      selectedOption.selected = true
      this._selectedOptionContainerDom.appendChild(selectedOption)
    } else {
      removeClass(option, this.selectedClassList)
      addClass(option, this.unselectedClassList)
      selectedOption.selected = false
      selectedOption.remove();
    }

    this.changeSelectionValues()
  }

  changeSelection(event: Event): void {
    const target = event.currentTarget as HTMLOptionElement;
    target.selected = !target.selected
    this.changeOptionStatus(target)
  }

  connect(): void {
    this._optionOpen = false;
    this.selectTarget.classList.add("hidden");
    this.options = Array.from(this.selectTarget.options)
    this.optionsDom = this._options

    this._parentContainerDom = this.createParentContainerDOM()
    this._selectionDom = this.createSelectionDom()

    this._selectedOptionsDom = this.createSelectedElements(this._options)

    this._selectedOptionContainerDom = make("div", [])

    for (const i in this._selectedOptionsDom) {
      const option = this._selectedOptionsDom[i] as HTMLOptionElement;

      if (option.selected) {
        this._selectedOptionContainerDom.appendChild(this._selectedOptionsDom[i])
      }
    }

    this._selectionDom.appendChild(this._selectedOptionContainerDom)

    this._optionContainerDom = this.createOptionContainerDOM()

    this.element.appendChild(this._parentContainerDom)
    this._parentContainerDom.appendChild(this._selectionDom)
    this._parentContainerDom.appendChild(this._optionContainerDom)

    document.addEventListener("click", (event: Event) => {
      if (this._optionContainerDom.firstElementChild.classList.contains("hidden")) return;

      const target = event.target as HTMLElement;
      if (this._parentContainerDom.contains(target)) return

      this._optionOpen = !this._optionOpen
      addClass(this._optionContainerDom.firstElementChild as HTMLElement, ["hidden"])

    })
  }

  changeOptionVisibility(event: Event): void {
    this._optionOpen = !this._optionOpen
    const target = event.target as HTMLElement;

    if (this._selectedOptionContainerDom.contains(target)) return;

    if (this._optionOpen) {
      removeClass(this._optionContainerDom.firstElementChild as HTMLElement, ["hidden"])
    } else {
      addClass(this._optionContainerDom.firstElementChild as HTMLElement, ["hidden"])
    }
  }
}
