import axios, { AxiosInstance } from "axios";
import { Controller } from "@hotwired/stimulus"

class WorkListPorps extends Controller {
  urlValue!: string

  editButtonTarget!: HTMLElement;
  editButtonTargets!: Array<HTMLElement>;
  hasEditButtonTarget!: boolean;

  statusTarget!: HTMLElement;
  statusTargets!: Array<HTMLElement>;
  hasStatusTarget!: boolean;

  modalTarget!: HTMLElement;
  modalTargets!: Array<HTMLElement>;
  hasModalTarget!: boolean;
}

export default class extends (Controller as typeof WorkListPorps) {
  get http(): AxiosInstance {
    const csrfMeta = document.querySelector("[name=csrf-token]") as HTMLMetaElement;

    return axios.create({
      headers: {
        common: {
          "X-CSRF-Token": csrfMeta?.content
        }
      }
    })
  }

  STATUS = {
    published: "published",
    draft: "draft",
  }

  static values = {
    url: String
  }

  static targets = ["editButton", "modal", "status"]

  connect(): void {

    document.addEventListener("click", (event: Event) => {
      if (this.modalTarget.classList.contains("hidden")) return;

      if (this.modalTarget.contains(event.target as HTMLElement) || this.editButtonTarget.contains(event.target as HTMLElement)) {
        return;
      }

      this.modalTarget.classList.add("hidden")

    })
  }

  open(): void {
    this.modalTarget.classList.remove("hidden")
  }

  updateStatus(event: Event): void {
    const target = event.target as HTMLSelectElement;

    this.http.patch(`${this.urlValue}.json`, { project: { status: target.value }}).then(() => {
      if (target.value == this.STATUS.published) {
        this.statusTarget.classList.remove("work-list-status__draft")
        this.statusTarget.classList.add("work-list-status__published")
      } else {
        this.statusTarget.classList.remove("work-list-status__published")
        this.statusTarget.classList.add("work-list-status__draft")
      }
    })
  }

  changeHighlightStatus(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.http.patch(`${this.urlValue}.json`, { project: { is_highlight: target.checked }})
  }

  changeHighlightOrder(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.http.patch(`${this.urlValue}.json`, { project: { highlight_order: target.value }})
  }
}
