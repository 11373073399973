import slugify from "slugify"
import { Controller } from "@hotwired/stimulus"
import { generateRandomToken } from "../lib/utilities"

class AutoslugProps extends Controller {
  slugTarget: HTMLInputElement
}

export default class extends (Controller as typeof AutoslugProps) {
  static targets = ["slug"]
  initialSlug: string
  token: string

  connect(): void {
    this.initialSlug = this.slugTarget.value
    this.token = generateRandomToken()
  }

  get isModified(): boolean {
    return !this.initialSlug.match(/^untitled-/)
  }

  copySlug(event: Event): void {
    const input = event.target as HTMLInputElement;
    const validSlug = slugify(`${input.value}-${this.token}`)

    if (this.isModified) return

    this.slugTarget.value = validSlug.toLocaleLowerCase()
  }
}
