import buttonIcon from './svg/button-icon.svg';

/**
 * Class for working with UI:
 *  - rendering base structure
 *  - show/hide preview
 *  - apply tune view
 */
export default class Ui {
  side = 'left';
  /**
   * @param {object} ui - image tool Ui module
   * @param {object} ui.api - Editor.js API
   * @param {ImageConfig} ui.config - user config
   * @param {Function} ui.onSelectFile - callback for clicks on Select file button
   * @param {boolean} ui.readOnly - read-only mode flag
   */
  constructor({ api, config, onSelectFile, readOnly }) {
    this.api = api;
    this.config = config;
    this.onSelectFile = onSelectFile;
    this.readOnly = readOnly;
    this.nodes = {
      wrapper: make('div', [this.CSS.baseClass, this.CSS.wrapper, 'grid', 'grid-cols-6', 'gap-6']),
      left: make('div', ['col-span-6', 'md:col-span-3']),
      right: make('div', ['col-span-6', 'md:col-span-3']),
      imageContainerLeft: make('div', [ this.CSS.imageContainer ]),
      imageContainerRight: make('div', [ this.CSS.imageContainer ]),
      fileButtonLeft: this.createFileButton({name: "left"}),
      fileButtonRight: this.createFileButton({name: "right"}),
      imageEl: undefined,
      imagePreloaderLeft: make('div', this.CSS.imagePreloader),
      imagePreloaderRight: make('div', this.CSS.imagePreloader),
      captionLeft: make('div', [this.CSS.input, this.CSS.caption], {
        contentEditable: !this.readOnly,
      }),

      captionRight: make('div', [this.CSS.input, this.CSS.caption], {
        contentEditable: !this.readOnly,
      }),
    };

    /**
     * Create base structure
     *  <wrapper>
     *    <image-left>
      *    <image-container>
      *      <image-preloader />
      *    </image-container>
      *    <caption />
      *    <select-file-button />
     *    </image-left>
     *  </wrapper>
     */
    this.nodes.captionLeft.dataset.placeholder = this.config.captionPlaceholder;
    this.nodes.imageContainerLeft.appendChild(this.nodes.imagePreloaderLeft);
    this.nodes.left.appendChild(this.nodes.imageContainerLeft);
    this.nodes.left.appendChild(this.nodes.captionLeft);
    this.nodes.left.appendChild(this.nodes.fileButtonLeft);

    this.nodes.imageContainerRight.appendChild(this.nodes.imagePreloaderRight);
    this.nodes.right.appendChild(this.nodes.imageContainerRight);
    this.nodes.right.appendChild(this.nodes.captionRight);
    this.nodes.right.appendChild(this.nodes.fileButtonRight);

    this.nodes.wrapper.appendChild(this.nodes.left);
    this.nodes.wrapper.appendChild(this.nodes.right);
  }

  /**
   * CSS classes
   *
   * @returns {object}
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      input: this.api.styles.input,
      button: this.api.styles.button,

      /**
       * Tool's classes
       */
      wrapper: 'image-tool',
      imageContainer: 'image-tool__image',
      imagePreloader: 'image-tool__image-preloader',
      imageEl: 'image-tool__image-picture',
      caption: 'image-tool__caption',
    };
  }

  /**
   * Ui statuses:
   * - empty
   * - uploading
   * - filled
   *
   * @returns {{EMPTY: string, UPLOADING: string, FILLED: string}}
   */
  static get status() {
    return {
      EMPTY: 'empty',
      UPLOADING: 'loading',
      FILLED: 'filled',
    };
  }

  /**
   * Renders tool UI
   *
   * @param {ImageToolData} toolData - saved tool data
   * @returns {Element}
   */
  render(toolData) {

    if (!toolData.fileLeft || Object.keys(toolData.fileLeft).length === 0) {
      this.toggleStatus(Ui.status.EMPTY, 'left');
    } else {
      this.toggleStatus(Ui.status.FILLED, 'left');
    }

    if (!toolData.fileRight || Object.keys(toolData.fileRight).length === 0) {
      this.toggleStatus(Ui.status.EMPTY, 'right');
    } else {
      this.toggleStatus(Ui.status.UPLOADING, 'right');
    }


    return this.nodes.wrapper;
  }
  /**
   * Creates upload-file button
   *
   * @returns {Element}
   */
  createFileButton(name = {}) {
    const button = make('div', [ this.CSS.button ], name);

    button.innerHTML = this.config.buttonContent || `${buttonIcon} ${this.api.i18n.t('Select an Image')}`;

    button.addEventListener('click', (e) => {
      this.side = e.target.name;
      this.onSelectFile(e.target);
    });

    return button;
  }

  /**
   * Shows uploading preloader
   *
   * @param {string} src - preview source
   * @returns {void}
   */
  showPreloader(src, side = 'left') {

    if (side == 'left') {
      this.nodes.imagePreloaderLeft.style.backgroundImage = `url(${src})`;
    } else {
      this.nodes.imagePreloaderRight.style.backgroundImage = `url(${src})`;
    }

    this.toggleStatus(Ui.status.UPLOADING, this.side);
  }

  /**
   * Hide uploading preloader
   *
   * @returns {void}
   */
  hidePreloader() {
    if (this.side == 'left') {
      this.nodes.imagePreloaderLeft.style.backgroundImage = '';
    } else {
      this.nodes.imagePreloaderRight.style.backgroundImage = '';
    }
    this.toggleStatus(Ui.status.EMPTY, this.side);
  }

  /**
   * Shows an image
   *
   * @param {string} url - image source
   * @returns {void}
   */
  fillImage(url, side = 'left') {
    this.side = side;
    /**
     * Check for a source extension to compose element correctly: video tag for mp4, img — for others
     */
    const tag = /\.mp4$/.test(url) ? 'VIDEO' : 'IMG';

    const attributes = {
      src: url,
    };

    /**
     * We use eventName variable because IMG and VIDEO tags have different event to be called on source load
     * - IMG: load
     * - VIDEO: loadeddata
     *
     * @type {string}
     */
    let eventName = 'load';

    /**
     * Update attributes and eventName if source is a mp4 video
     */
    if (tag === 'VIDEO') {
      /**
       * Add attributes for playing muted mp4 as a gif
       *
       * @type {boolean}
       */
      attributes.autoplay = true;
      attributes.loop = true;
      attributes.muted = true;
      attributes.playsinline = true;

      /**
       * Change event to be listened
       *
       * @type {string}
       */
      eventName = 'loadeddata';
    }

    /**
     * Compose tag with defined attributes
     *
     * @type {Element}
     */
    this.nodes.imageEl = make(tag, this.CSS.imageEl, attributes);

    /**
     * Add load event listener
     */
    this.nodes.imageEl.addEventListener(eventName, () => {
      this.toggleStatus(Ui.status.FILLED, this.side);

      /**
       * Preloader does not exists on first rendering with presaved data
       */
      if (this.nodes.imagePreloaderLeft) {
        this.nodes.imagePreloaderLeft.style.backgroundImage = '';
      }
    });

    if (this.side == 'left') {
      this.nodes.imageContainerLeft.appendChild(this.nodes.imageEl);
    } else {
      this.nodes.imageContainerRight.appendChild(this.nodes.imageEl);
    }

  }

  /**
   * Shows caption input
   *
   * @param {string} text - caption text
   * @returns {void}
   */
  fillCaption(text, side) {
    if (side === 'left') {
      this.nodes.captionLeft && (this.nodes.captionLeft.innerHTML = text);
    } else {
      this.nodes.captionRight && (this.nodes.captionRight.innerHTML = text);
    }
  }

  /**
   * Changes UI status
   *
   * @param {string} status - see {@link Ui.status} constants
   * @returns {void}
   */
  toggleStatus(status, side) {
    for (const statusType in Ui.status) {
      if (Object.prototype.hasOwnProperty.call(Ui.status, statusType)) {
        this.nodes[side].classList.toggle(`${this.CSS.wrapper}--${Ui.status[statusType]}`, status === Ui.status[statusType]);
      }
    }
  }

  /**
   * Apply visual representation of activated tune
   *
   * @param {string} tuneName - one of available tunes {@link Tunes.tunes}
   * @param {boolean} status - true for enable, false for disable
   * @returns {void}
   */
  applyTune(tuneName, status) {
    this.nodes.wrapper.classList.toggle(`${this.CSS.wrapper}--${tuneName}`, status);
  }
}

/**
 * Helper for making Elements with attributes
 *
 * @param  {string} tagName           - new Element tag name
 * @param  {Array|string} classNames  - list or name of CSS class
 * @param  {object} attributes        - any attributes
 * @returns {Element}
 */
export const make = function make(tagName, classNames = null, attributes = {}, htmlAttributes = {}) {
  // eslint-disable-next-line no-undef
  const el = document.createElement(tagName);

  if (Array.isArray(classNames)) {
    el.classList.add(...classNames);
  } else if (classNames) {
    el.classList.add(classNames);
  }

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  for(const attrName in htmlAttributes) {
    el.setAttribute(attrName, htmlAttributes[attrName]);
  }

  return el;
};
