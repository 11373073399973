import { Controller } from "@hotwired/stimulus";
import dayjs from '../lib/dayutils'

class TimeProps extends Controller {
  timeZoneValue: string;
  dateValue: string;
  formatValue: string;
}

export default class TimeController extends (Controller as typeof TimeProps) {
   static values = {
    timeZone: String,
    date: String,
    format: String
  };

  element: HTMLSpanElement;

  connect(): void {
    if (!this.dateValue || !this.timeZoneValue || !this.formatValue) return
    
    const currentTz = dayjs.tz.guess()

    const t = dayjs.tz(this.dateValue, this.timeZoneValue).tz(currentTz);
    (this.element as HTMLSpanElement).innerHTML = t.format(this.formatValue)
  }
}