import { Controller } from "@hotwired/stimulus"
import * as qs from "query-string";


class PaginationProps extends Controller {
}

export default class extends (Controller as typeof PaginationProps) {

  changeItem(event: Event): void {
    const target  = event.target as HTMLSelectElement;
    let path = location.pathname
    const query = qs.parse(location.search)
    query["items"] = target.value
    delete query["page"]

    path = `${path}?${qs.stringify(query)}`

    window.location.href = path
  }
}
