import { Controller } from "@hotwired/stimulus"

class ConfigurablePageProps extends Controller {
  drawerTarget!: HTMLElement
  triggerTarget!: HTMLElement
}


export default class extends (Controller as typeof ConfigurablePageProps) {
  static targets = ["drawer", "trigger"]

  connect(): void {
    document.addEventListener("click", (event: Event) => {
      if (!this.isOpen) return;

      if (this.drawerTarget.contains(event.target as HTMLElement) || this.triggerTarget.contains(event.target as HTMLElement)) {
        return;
      }

      this.closeDrawer()

    })
  }

  toogleDrawer(): void {
    this.isOpen ? this.closeDrawer() : this.openDrawer();
  }

  get isOpen(): boolean {
    return this.element.classList.contains("drawer-opened")
  }

  openDrawer(): void {
    this.element.classList.add("drawer-opened")
  }

  closeDrawer(): void {
    this.element.classList.remove("drawer-opened")
  }
}
