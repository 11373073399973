import { Controller } from "@hotwired/stimulus";

class BlogFilterParams extends Controller {
  categoryTarget!: HTMLSelectElement;
  languageTarget!: HTMLSelectElement;
}

export default class extends (Controller as typeof BlogFilterParams) {
  static targets = ["category", "language"];

  changeFilter(): void {
    const selectedCategory = this.categoryTarget.selectedOptions[0]?.value
    const selectedLanguage = this.languageTarget.selectedOptions[0]?.value

    let newUrl = "/blog?unpaginated=1"

    if (selectedCategory) newUrl += `&category=${selectedCategory}`

    if (selectedLanguage) newUrl += `&language=${selectedLanguage}`

    location.href = newUrl
  }
}
