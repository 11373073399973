import { Controller } from "@hotwired/stimulus"

class HighlightProps extends Controller {
  orderTarget!: HTMLInputElement
  orderTargets!: HTMLInputElement[]
  hasOrderTarget!: boolean

  highlightTarget!: HTMLInputElement
  highlightTargets!: HTMLInputElement[]
  hasHighlightTarget!: boolean
}

export default class extends (Controller as typeof HighlightProps) {
  static targets = ["order", "highlight"]

  connect(): void {
    this.handleOrder()
  }

  handleOrder(): void {
    this.orderTarget.disabled = !this.highlightTarget.checked
  }
}
