import { Controller } from "@hotwired/stimulus";
import { parse, stringify } from 'query-string';
import dayjs from '../lib/dayutils'

export default class TzController extends Controller {
  connect(): void {
    const qs = parse(location.search)

    const tz = qs["time_zone"]

    if (tz) return

    qs["time_zone"] = dayjs.tz.guess()

    const url = `${location.pathname}?${stringify(qs)}`

    location.href = url
  }
}