import { Controller } from "@hotwired/stimulus";
import axios from "axios"

class MenuItemProps extends Controller {
  idTarget!: HTMLInputElement;
  orderTarget!: HTMLInputElement;
}

export default class extends (Controller as typeof MenuItemProps) {
  static targets = ["id", "order"]

  initialize(): void {
    this.element.addEventListener("rearranged", (event: Event) => {
      const target = event.target as HTMLElement;
      this.orderTarget.value = target.getAttribute("data-order")
      this.submit()
    })
  }

  submit(): void {
    const csrfMeta = document.querySelector("[name=csrf-token]") as HTMLMetaElement;
    const form = new FormData(this.element as HTMLFormElement);

    if (!form.get("site_menu[name]") ||  !form.get("site_menu[link]")) {
      return
    }
    axios.post("/dashboard/site_menus", form, {headers: {'X-CSRF-TOKEN': csrfMeta?.content}}).then(({ data }) => {
      this.idTarget.value = data.id
    })
  }

  destroy(): void {
    const id = this.idTarget.value
    if (!id) {
      this.element.remove()
      return
    }

    const csrfMeta = document.querySelector("[name=csrf-token]") as HTMLMetaElement;

    axios.delete("/dashboard/site_menus", {params: {"site_menu[id]": id}, headers: {'X-CSRF-TOKEN': csrfMeta?.content}}).then(() => {
      this.element.remove()
    })
  }
}
