import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';
import { make } from "../lib/dom";

class Menus extends Controller {
  mainTarget!: HTMLElement
}


export default class extends (Controller as typeof Menus) {
  static targets = ["main"]
  connect(): void {
    Sortable.create(this.mainTarget)

    this.mainTarget.ownerDocument.addEventListener("dragend", (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const oldOrder = target.getAttribute("data-order")


      const children = Array.prototype.slice.call(this.mainTarget.children);

      const newOrder = children.indexOf(target) + 1;

      if (newOrder == oldOrder) return;

      for (const i in children) {
        const child = children[i];
        child.setAttribute("data-order", parseInt(i) + 1)
        child.dispatchEvent(new Event("rearranged"))
      }
    })
  }

  /*
  <form class="grid grid-cols-12 gap-6 w-8/12 py-2" data-controller="menus-item" data-order="<%= menu.order %>" >
    <input type="hidden" name="site_menu[order]" value="<%= menu.order %>" data-menus-item-target="order" >
    <input type="hidden" name="site_menu[menu_name]" value="<%= HeaderMenu::MENU_NAME %>">
    <input type="hidden" data-menus-item-target="id" name="site_menu[id]" value="<%= menu.id %>">
    <div class="col-span-5 flex">
      <button type="button" class="mx-3">
        <i class="icon-drag text-icon"></i>
      </button>
      <input data-action="change->menus-item#submit" name="site_menu[name]" value="<%= menu.name %>" placeholder="Menu Name" type="text" class="w-full border border-galium focus:outline-none focus:border-leaf focus:ring-0 rounded">
    </div>

    <div class="col-span-7 flex">
      <input data-action="change->menus-item#submit" name="site_menu[link]" value="<%= menu.link %>" placeholder="Link" type="text" class="w-full border border-galium focus:outline-none focus:border-leaf focus:ring-0 rounded">
      <button type="button" class="mx-3">
        <i class="icon-close text-icon text-2xl"></i>
      </button>
    </div>
  </form>
  */

  addMenu(): void {
    const container = make("form", "grid grid-cols-12 gap-6 w-8/12 py-2".split(" "), {}, {
        "data-controller": "menus-item",
        "data-order": this.mainTarget.children.length + 1
    })

    container.appendChild(
      make("input", [], {}, {
        type: "hidden",
        name: "site_menu[menu_name]",
        value: this.mainTarget.getAttribute("data-menu-name"),
      })
    )

    container.appendChild(
      make("input", [], {}, {
        type: "hidden",
        name: "site_menu[order]",
        "data-menus-item-target": "order",
        value: this.mainTarget.children.length + 1
      })
    )

    container.appendChild(
      make("input", [], {}, {
        type: "hidden",
        "data-menus-item-target": "id",
        name: "site_menu[id]"
      })
    )

    const nameContainer = make("div", "col-span-5 flex".split(" "))
    const dragButton = make("button", ["mx-3"], {}, {type: "button"})
    dragButton.appendChild(
      make("i", "icon-drag text-icon".split(" "))
    )
    nameContainer.appendChild(dragButton)
    nameContainer.appendChild(
      make("input", "w-full border border-galium focus:outline-none focus:border-leaf focus:ring-0 rounded".split(" "), {}, {
        "data-action": "change->menus-item#submit",
        "name": "site_menu[name]",
        "placeholder": "Menu Name",
        "type": "text"
      })
    )

    const linkContainer = make("div", "col-span-7 flex".split(" "))
    linkContainer.appendChild(
      make("input", "w-full border border-galium focus:outline-none focus:border-leaf focus:ring-0 rounded".split(" "), {}, {
        "data-action": "change->menus-item#submit",
        "name": "site_menu[link]",
        "placeholder": "Link",
        "type": "text"
      })
    )

    const closeButton = make("button", ["mx-3"], {}, {type: "button"})
    closeButton.appendChild(
      make("i", "icon-close text-icon".split(" "))
    )
    linkContainer.appendChild(closeButton)

    container.appendChild(nameContainer)
    container.appendChild(linkContainer)


    this.mainTarget.appendChild(container)
  }
}
