import { Controller } from "@hotwired/stimulus"
import axios from "axios"
import { make } from "../lib/dom";

class ImageControllerProps extends Controller {
  inputTarget!: HTMLInputElement;
  inputTargets!: Array<HTMLInputElement>
  hasInputTarget!: boolean;

  fileNameTarget!: HTMLInputElement;
  fileNameTargets!: Array<HTMLInputElement>;
  hasFileTarget!: boolean;
}

export default class extends (Controller as typeof ImageControllerProps) {
  static targets = ["input", "fileName"]

  fileChanged(): void {
    const file = this.inputTarget.files[0]
    const fileName = file ? file.name : ""
    this.fileNameTarget.textContent = fileName
    this.updatePlaceholder(file)
  }

  updatePlaceholder(file) {
    let element = this.element.querySelector('.image-placeholder')
    if (!element) return;

    let image = element.querySelector('img')
    image.src = URL.createObjectURL(file)
  }

  deleteFile(event: Event): void {
    const target = event.target as HTMLElement;
    const url = target.getAttribute("data-url")
    if (!url) return;
    axios.delete(url).then(() => {
      this.inputTarget.value = null
      this.element.querySelector(".image-placeholder")?.classList.add("hidden")
      const icon = make("i", "icon-image text-3xl text-icon".split(" "))
      target.parentNode.prepend(icon);
      target.remove()
    })
  }
}
