import { Controller } from "@hotwired/stimulus"

class MenuButtonProps extends Controller {
  buttonTarget!: Element
  buttonTargets!: Element[]
  hasButtonTarget!: boolean

  containerTarget!: Element
  containerTargets!: Element[]
  hasContainerTarget!: boolean
}

export default class extends (Controller as typeof MenuButtonProps) {
  static targets = ["button", "container"]

  connect(): void {
    this.buttonTarget.addEventListener("click", () => {
      if (this.containerTarget.classList.contains("active")) {
        this.containerTarget.classList.remove("active")
        document.body.classList.remove("no-scroll")
        setTimeout(() => {
          document.querySelector('.navbar').classList.remove("active")
        }, 250);
      } else {
        this.containerTarget.classList.add("active")
        document.body.classList.add("no-scroll")
        document.querySelector('.navbar').classList.add("active")
      }
    })
  }
}
