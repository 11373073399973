export const make = (tag: string, classList: Array<string>, attributes: any = {} , htmlAttributes: any = {}) : HTMLElement => {
  const el = document.createElement(tag);

  el.classList.add(...classList)

  for (const attrName in attributes) {
    el[attrName] = attributes[attrName];
  }

  for(const attrName in htmlAttributes) {
    el.setAttribute(attrName, htmlAttributes[attrName]);
  }
  return el
}

export const removeClass = (el: HTMLElement, classList: Array<string>): void => {
  el.classList.remove(...classList)
}

export const addClass = (el: HTMLElement, classList: Array<string>): void => {
  el.classList.add(...classList)
}

export const hiddenElementChanged = "hidden-element:changed"
