import "photoswipe/dist/photoswipe.css"
import "photoswipe/dist/default-skin/default-skin.css"

import { Controller } from "@hotwired/stimulus"

import PhotoSwipe from "photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default.min.js"

class ImageViewerProps extends Controller {
  imagesValue!: string;
}

export default class extends (Controller as typeof ImageViewerProps) {
  static values = {
    images: Array
  }
  open(event: Event): void {
    const target = event.target as HTMLElement;
    const pswp = document.querySelector(".pswp")
    const images = Array.from(this.imagesValue).map(i => ({src: i,w: 0, h: 0}))

    const gallery = new PhotoSwipe(pswp, PhotoSwipeUI_Default, images, {
      closeOnScroll: false,
      index: parseInt(target.getAttribute("index")) || 0
    })

    gallery.listen('gettingData', function(index, item) {
      if (item.w < 1 || item.h < 1) { // unknown size
        const img = new Image();
        img.onload = function() { // will get size after load
        // eslint-disable-next-line
        item.w = this.width; // set image width
        item.h = this.height; // set image height
          gallery.invalidateCurrItems(); // reinit Items
          gallery.updateSize(true); // reinit Items
        }
        img.src = item.src; // let's download image
      }
    });

    gallery.init()
  }
}
