import { Controller } from "@hotwired/stimulus"

class MenuItemProps extends Controller {
  // menuValue!: string;
}

export default class extends (Controller as typeof MenuItemProps) {
  // static values = { menu: String }

  connect(): void {
    this.element.addEventListener("click", () => {
      if (this.element.classList.contains("active")) {
        this.element.classList.remove("active")
      } else {
        this.element.classList.add("active")
      }
    })
  }

}
