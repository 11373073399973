import { Controller } from "@hotwired/stimulus"
import autosize from "autosize"

class TextAutosizeProps extends Controller {

}

export default class extends (Controller as typeof TextAutosizeProps) {
  connect(): void {
    autosize(this.element)
  }
}


