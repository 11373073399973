import { Controller } from "@hotwired/stimulus"

class StatusFromControllerProps extends Controller {
  statusTarget!: HTMLInputElement
  statusTargets!: HTMLInputElement[]
  hasStatusTarget!: boolean

  submitTarget!: HTMLInputElement
  submitTargets!: HTMLInputElement[]
  hasSubmitTarget!: boolean
}

export default class extends (Controller as typeof StatusFromControllerProps) {
  static targets = ["status", "submit"]

  connect(): void {
    this.changeSubmitText()
  }

  statusChanged(): void {
    this.changeSubmitText()
  }

  changeSubmitText(): void {
    if (this.statusTarget.value == "draft") {
      this.submitTarget.value = "Draft Project"
    } else {
      this.submitTarget.value = "Publish Project"
    }
  }
}
