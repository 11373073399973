import { make } from "./double_images/ui"

interface ResponsiveTwoNodes {
  container: HTMLElement;
  left: HTMLElement;
  right: HTMLElement;
}

interface ResponsiveTwoData {
  left?: string
  right?: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ResponsiveTwoConfig {}

interface EditorJSConfig<T, U> {
  data: T,
  config: U
  api: any
  readOnly: boolean
}

export class ResponsiveTwo {
  api: any;
  readOnly: boolean;
  nodes: ResponsiveTwoNodes;
  config: ResponsiveTwoConfig;
  _data: ResponsiveTwoData;

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this.config = config;

    this.nodes = {
      container: make("div", ["grid", "grid-cols-12", "gap-6"]),
      left: make("div", ["col-span-12", "sm:col-span-6", "ce-paragraph", "cdx-block"], {}, {contenteditable: true}),
      right: make("div", ["col-span-12", "sm:col-span-6", "ce-paragraph", "cdx-block"], {}, {contenteditable: true}),
    }

    this.nodes.container.appendChild(this.nodes.left)
    this.nodes.container.appendChild(this.nodes.right)
    this.data = data
  }

  static get toolbox() {
    return {
      title: "Responsive Two Blocks",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="-200 -250 1000 1000" xmlns:v="https://vecta.io/nano"><path d="M187.753 0H51.18C22.959 0 0 22.958 0 51.179v136.575c0 28.221 22.959 51.179 51.18 51.179h136.573c28.221 0 51.18-22.958 51.18-51.179V51.179C238.933 22.958 215.974 0 187.753 0zm34.114 187.754c0 18.81-15.303 34.112-34.114 34.112H51.18c-18.81 0-34.114-15.302-34.114-34.112V51.179c0-18.81 15.303-34.112 34.114-34.112h136.573c18.81 0 34.114 15.302 34.114 34.112v136.575zm-34.114 85.313H51.18C22.959 273.067 0 296.025 0 324.246v136.575C0 489.042 22.959 512 51.18 512h136.573c28.221 0 51.18-22.958 51.18-51.179V324.246c0-28.221-22.959-51.179-51.18-51.179zm34.114 187.754c0 18.81-15.303 34.112-34.114 34.112H51.18c-18.81 0-34.114-15.302-34.114-34.112V324.246c0-18.81 15.303-34.113 34.114-34.113h136.573c18.81 0 34.114 15.302 34.114 34.112v136.576zM460.82 0H324.247c-28.221 0-51.18 22.958-51.18 51.179v136.575c0 28.221 22.959 51.179 51.18 51.179H460.82c28.221 0 51.18-22.958 51.18-51.179V51.179C512 22.958 489.041 0 460.82 0zm34.113 187.754c0 18.81-15.303 34.112-34.114 34.112H324.247c-18.81 0-34.113-15.302-34.113-34.112V51.179c0-18.81 15.303-34.112 34.114-34.112H460.82c18.81 0 34.113 15.302 34.113 34.112v136.575zm-34.113 85.313H324.247c-28.221 0-51.18 22.958-51.18 51.179v136.575c0 28.221 22.959 51.179 51.18 51.179H460.82c28.221 0 51.18-22.958 51.18-51.179V324.246c0-28.221-22.959-51.179-51.18-51.179zm34.113 187.754c0 18.81-15.303 34.112-34.114 34.112H324.247c-18.81 0-34.113-15.302-34.113-34.112V324.246c0-18.81 15.303-34.113 34.114-34.113H460.82c18.81 0 34.113 15.302 34.113 34.112v136.576z"/></svg>`
    }
  }

  render(): HTMLElement {
    return this.nodes.container;
  }

  save(): ResponsiveTwoData {
    const data: ResponsiveTwoData = {
      left: this.nodes.left.innerHTML,
      right: this.nodes.right.innerHTML,
    }
    return data
  }

  get data(): ResponsiveTwoData {
    this._data.left = this.nodes.left.innerHTML;
    this._data.right = this.nodes.right.innerHTML;

    return this._data;
  }

  set data(data: ResponsiveTwoData) {
    this._data = data || {};
    data?.left && (this.nodes.left.innerHTML = data?.left);
    data?.right && (this.nodes.right.innerHTML = data?.right);
  }

}
