import { Controller } from "@hotwired/stimulus"

class SideMenuProps extends Controller {
  drawerTarget!: Element
  drawerTargets!: Element
  hasDrawerTarget!: boolean
}

export default class extends (Controller as typeof SideMenuProps) {
  static targets = ["trigger", "drawer"]

  open(): void {
    console.log("RUNNING")
    this.drawerTarget.classList.add("active")
  }

  close(): void {
    this.drawerTarget.classList.remove("active")
  }
}
